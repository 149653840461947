import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const TermsOfUse = ({ data, location }) => {
  const content = data.contentfulLegal.termsOfUse

  return (
    <Layout location={location}>
      <Seo title="Terms of Use" />
      <div className="section-wrapper page-main">
        <div className="content-column">
          <div className="text-section">{renderRichText(content)}</div>
        </div>
      </div>
    </Layout>
  )
}

export default TermsOfUse

export const pageQuery = graphql`
  query TermsContent {
    site {
      siteMetadata {
        title
      }
    }
    contentfulLegal {
      termsOfUse {
        raw
      }
    }
  }
`
